import { Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { firstValueFrom, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpService } from "./http.service";
import { Router } from "@angular/router";
import { CurrentTenantService } from "../current-tenant.service";
import { ToastService } from "../toast.service";
import { Translation } from "../translations.service";
import { HttpRequestMethod } from "./http.request.model";
import { HttpRequestFactory } from "./http.request-factory.service";
import { HttpError } from "./http.error";
import { TenantId } from "../../_types/tenant-id";
import { AuthUserStoreService } from "../../_store/auth-user-store.service";
import { AccessToken } from "../../_models/access-token";
import { IQ_FE_VERSION } from "../../../environments/iq-fe-version";

export type ReportingFileType = "csv" | "xlsx";

@Injectable({
  providedIn: "root",
})
export class ServerService {
  private _requestedWith: string;
  constructor(
    private _httpService: HttpService,
    private _router: Router,
    private _toastService: ToastService,
    private _currentTenantService: CurrentTenantService,
    private _authUserStoreService: AuthUserStoreService
  ) {
    this._requestedWith = this._frontendVersionString();
  }

  private _createRequestFactory<Method extends HttpRequestMethod>(
    method: Method,
    route: string,
    data: any
  ): HttpRequestFactory {
    const requestFactory =
      method === "GET"
        ? new HttpRequestFactory(method, route, undefined, data, undefined)
        : new HttpRequestFactory(method, route, data, undefined, undefined);

    requestFactory.addHeader("X-Requested-With", this._requestedWith);

    return requestFactory;
  }

  private _requestRequestObj(
    requestFactory: HttpRequestFactory
  ): Observable<any> {
    return this._httpService.request(requestFactory).pipe(
      catchError((error) => {
        this._handleHttpErrorResponse(error);

        throw error;
      })
    );
  }

  private _requestRequestFile(
    requestFactory: HttpRequestFactory
  ): Observable<any> {
    return this._httpService.requestFile(requestFactory).pipe(
      catchError((error) => {
        this._handleHttpErrorResponse(error);

        throw error;
      })
    );
  }

  private _addAuthToRequest(requestFactory: HttpRequestFactory): void {
    this._addAccessTokenToRequest(
      requestFactory,
      this._authUserStoreService.current?.accessToken || null
    );
  }

  private _addAccessTokenToRequest(
    requestFactory: HttpRequestFactory,
    accessToken: AccessToken | null
  ): void {
    requestFactory.addDynamicHeader("Authorization", () => {
      if (accessToken) {
        return `Bearer ${accessToken.toString()}`;
      } else {
        console.warn("Missing access-token");
        return undefined;
      }
    });
  }

  private _addTenantIdToRequest(
    requestFactory: HttpRequestFactory,
    tenantIds: TenantId | null | TenantId[]
  ): void {
    if (tenantIds) {
      requestFactory.addHeader(
        "X-Tenant-Id",
        Array.isArray(tenantIds) ? tenantIds.join(",") : tenantIds
      );
    }
  }

  public requestUnauthorized<T>(
    method: HttpRequestMethod,
    route: string,
    data?: any
  ): Observable<T> {
    const request = this._createRequestFactory(method, route, data);

    return this._requestRequestObj(request);
  }

  public requestWithAccessToken<T>(
    method: HttpRequestMethod,
    route: string,
    accessToken: AccessToken,
    data?: any
  ): Observable<T> {
    const request = this._createRequestFactory(method, route, data);

    this._addAccessTokenToRequest(request, accessToken);

    return this._requestRequestObj(request);
  }

  public request<T>(
    method: HttpRequestMethod,
    route: string,
    data?: any
  ): Observable<T> {
    const requestFactory = this._createRequestFactory(method, route, data);

    this._addAuthToRequest(requestFactory);

    return this._requestRequestObj(requestFactory);
  }

  public requestUnauthorizedWithTenantId<T>(
    method: HttpRequestMethod,
    route: string,
    tenantId: TenantId | null,
    data?: any
  ): Observable<T> {
    const requestFactory = this._createRequestFactory(method, route, data);

    this._addTenantIdToRequest(requestFactory, tenantId);

    return this._requestRequestObj(requestFactory);
  }

  public requestWithTenantId<T>(
    method: HttpRequestMethod,
    route: string,
    tenantIds: TenantId | TenantId[] | null,
    data?: any
  ): Observable<T> {
    const requestFactory = this._createRequestFactory(method, route, data);

    this._addAuthToRequest(requestFactory);
    this._addTenantIdToRequest(requestFactory, tenantIds);

    return this._requestRequestObj(requestFactory);
  }

  public requestReportingFile(
    method: HttpRequestMethod,
    route: string,
    tenantId: TenantId,
    fileType: ReportingFileType | null,
    data?: any
  ): Observable<HttpResponse<Blob>> {
    const requestFactory = this._createRequestFactory(method, route, data);

    this._addAuthToRequest(requestFactory);
    this._addTenantIdToRequest(requestFactory, tenantId);

    switch (fileType) {
      case "csv":
        requestFactory.addHeader("Accept", "text/csv");
        break;
      case "xlsx":
        requestFactory.addHeader(
          "Accept",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        break;
    }

    return this._requestRequestFile(requestFactory);
  }

  public requestFileWithTenantId(
    method: HttpRequestMethod,
    route: string,
    tenantId: TenantId,
    data?: any
  ): Observable<HttpResponse<Blob>> {
    const requestFactory = this._createRequestFactory(method, route, data);

    this._addAuthToRequest(requestFactory);
    this._addTenantIdToRequest(requestFactory, tenantId);

    return this._requestRequestFile(requestFactory);
  }

  public async downloadFileWithTenantId(
    method: HttpRequestMethod,
    route: string,
    tenantId: TenantId,
    downloadName: string,
    data?: any
  ): Promise<void> {
    const body = await firstValueFrom(
      this.requestFileWithTenantId(method, route, tenantId, data).pipe(
        map((response: HttpResponse<Blob>) => {
          if (response.body) {
            return response.body;
          }
          throw new Error("Missing body in response");
        })
      )
    );
    const a = document.createElement("a");
    const objectUrl = URL.createObjectURL(body);
    a.href = objectUrl;
    a.download = downloadName;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  public get<T>(route: string, data?: any): Observable<T> {
    return this.request("GET", route, data);
  }

  private _handleHttpErrorResponse(error: HttpError) {
    switch (error.status) {
      case 400: // Bad Request
        break;

      case 401: // Unauthorized
        break;

      case 403: // Forbidden
        if (!this._currentTenantService.currentTenantId) {
          this._toastService.warning(
            new Translation("services.server.error.tenant_required.message"),
            new Translation("services.server.error.tenant_required.title"),
            undefined,
            { disableTimeOut: true }
          );
          this._router.navigate(["/admin/tenants"]);
        }
        break;

      case 404: // Not Found
        break;

      case 500: // Internal Server Error
        break;
    }
  }

  private _frontendVersionString(): string {
    return (
      "iq-fe/" +
      (location.hostname === "localhost"
        ? "local"
        : location.hostname.includes("stage")
        ? "stage"
        : "prod") +
      " " +
      IQ_FE_VERSION
    ).trim();
  }
}
