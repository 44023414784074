import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class HostsService {
  public readonly baseHost: string;
  public readonly collectHost: string;
  public readonly managementHost: string;
  public readonly normalizationHost: string;
  public readonly reportingsHost: string;
  public readonly securityHost: string;
  public readonly tenantHost: string;
  public readonly eventsHost: string;
  public readonly currencyImportHost: string;
  public readonly storageFtpHost: string;
  public readonly storageSftpHost: string;

  constructor() {
    // @ts-ignore
    const dynamicConfig: { [key: string]: string } = window.dynamicConfig;

    this.baseHost = dynamicConfig?.baseHost || this.detectBaseHost();
    this.collectHost = dynamicConfig?.collectHost || this.default("collect");
    this.managementHost =
      dynamicConfig?.managementHost || this.default("management");
    this.normalizationHost =
      dynamicConfig?.normalizationHost ||
      this.default("management") + "/normalization";
    this.reportingsHost =
      dynamicConfig?.reportingsHost || this.default("reportings");
    this.securityHost = dynamicConfig?.securityHost || this.default("security");
    this.tenantHost = dynamicConfig?.tenantHost || this.default("tenant");
    this.eventsHost =
      dynamicConfig?.eventsHost || this.default("events", "wss");
    this.currencyImportHost =
      dynamicConfig?.currencyImportHost || this.default("currency-import");
    this.storageFtpHost =
      dynamicConfig?.storageHost || this.default("storage", "ftp");
    this.storageSftpHost =
      dynamicConfig?.storageHost || this.default("storage", "sftp");
  }

  private detectBaseHost(): string {
    const host = window.location.hostname.split(".");
    let baseHost: string;
    if (host.length === 1) {
      baseHost = "stage.iq.digital-control.biz";
    } else {
      host.shift();
      baseHost = host.join(".");
    }
    return baseHost;
  }

  private default(prefix: string, protocol: string = "https"): string {
    return protocol + "://" + prefix + "." + this.baseHost;
  }
}
